@import '~antd/dist/reset.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.align-right {
  text-align: right;
}

.ant-table-title {
  padding: 16px 0px 16px 16px;
}

.highlighted-row {
  background-color: #f6ff00;
}

#root {
  min-height: 100vh;
}

#root > section {
  min-height: 100vh;
}

#dow-select-wrapper > .ant-select >  .ant-select-selector > .ant-select-selection-placeholder {
  opacity: 1;
}

.editable-cell {
    position: relative;
}

.editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.custom-select .ant-select-selector,
.custom-input,
.custom-input-number .ant-input-number-input {
    border: none !important;
    box-shadow: none !important;
}

.mobile-menu-list {
  width: 90vw;
  text-align: left;
  border-radius: 5px;
  padding: 0.5rem;
  height: 3.5rem;
}

.mobile-home-menu {
  display: flex;
  height: 12rem;
  margin: 1rem;
}

@media (max-width: 500px) {
  .ant-picker-panel-container {
      .ant-picker-panels,
      .ant-picker-datetime-panel {
          display: flex !important;
          flex-direction: column !important;
      }
  }
}